import React from "react";
import SEO from "../components/atoms/seo";

import Layout from "../components/organisms/layout";
import Heading from "../components/molecules/heading";
import BodyCopy from "../components/atoms/body-copy";

const ContactUsPage = () => (
  <Layout hideSecureForm>
    <SEO title="Contact Us" />
    <Heading title="Contact Us" weight="light" />
    <BodyCopy copy={"Contact us: [--PLACEHOLDER-CLIENT-EMAIL--]"} />
  </Layout>
);

export default ContactUsPage;
